import {
  ArconnectSigner,
  TokenType,
  TurboSigner,
} from '@ardrive/turbo-sdk/web';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import {
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
} from '@solana/web3.js';
import { useCallback, useEffect, useState } from 'react';
import { parseEther } from 'viem';
import {
  useAccount,
  useConnectors,
  useDisconnect,
  useSendTransaction,
  useSignMessage,
} from 'wagmi';
import { mainnet } from 'wagmi/chains';
import useWander from './useWander';

export type TransferTransactionResult = {
  txid: string;
  explorerURL: string;
};

export type AddressState = {
  address: string;
  token: TokenType;
  disconnect: () => void;
  explorerUrl: string;
  signer?: TurboSigner;
  submitNativeTransaction?: (
    amount: number,
    toAddress: string,
  ) => Promise<TransferTransactionResult>;
};

const useAddress = (): AddressState | undefined => {
  const [addressState, setAddressState] = useState<AddressState>();

  const { address, disconnect: arweaveDisconnect } = useWander();

  // eth
  const account = useAccount();
  const connectors = useConnectors();
  const { disconnectAsync: ethereumDisconnect } = useDisconnect();
  const { sendTransactionAsync } = useSendTransaction();

  // sol
  const {
    connected,
    publicKey,
    disconnect: solanaDisconnect,
    sendTransaction: solanaSendTransaction,
  } = useWallet();
  const solanaConnection = useConnection();

  const { signMessageAsync } = useSignMessage();

  const updateAddressState = useCallback(async () => {
    if (address) {
      if (address === addressState?.address) {
        return;
      }
      setAddressState({
        address: address,
        token: 'arweave',
        disconnect: arweaveDisconnect,
        explorerUrl: `https://viewblock.io/arweave/address/${address}`,
        signer: new ArconnectSigner(window.arweaveWallet),
      });
    } else if (connected && publicKey) {
      // Need to first check for SOL address before doing check for ETH address,
      // since user can connect with Phantom which supports both ETH and SOL.

      if (publicKey.toBase58() === addressState?.address) {
        return;
      }
      setAddressState({
        address: publicKey.toBase58(),
        token: 'solana',
        disconnect: async () => {
          await solanaDisconnect();
        },
        explorerUrl: `https://solscan.io/address/${publicKey.toBase58()}`,
        submitNativeTransaction: async (amount: number, toAddress: string) => {
          try {
            const recipientPubKey = new PublicKey(toAddress);

            const transaction = new Transaction();
            const sendSolInstruction = SystemProgram.transfer({
              fromPubkey: publicKey,
              toPubkey: recipientPubKey,
              lamports: amount * LAMPORTS_PER_SOL,
            });

            transaction.add(sendSolInstruction);

            const signature = await solanaSendTransaction(
              transaction,
              solanaConnection.connection,
            );

            const latestBlockHash =
              await solanaConnection.connection.getLatestBlockhash();

            const res = await solanaConnection.connection.confirmTransaction(
              {
                signature,
                blockhash: latestBlockHash.blockhash,
                lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
              },
              'processed',
            );
            if (res.value.err) {
              throw res.value.err;
            }

            return {
              txid: signature,
              explorerURL: `https://solscan.io/tx/${signature}`,
            };
          } catch (error) {
            console.error('Transaction failed', error);
            throw error;
          }
        },
      });
    } else if (account?.address && account?.connector?.name == 'MetaMask') {
      if (account.address === addressState?.address) {
        return;
      }

      setAddressState({
        address: account?.address,
        token: 'ethereum',
        disconnect: async () => {
          const connector = connectors.find((c) => c.name === 'MetaMask');
          await ethereumDisconnect({ connector });
        },
        explorerUrl: `https://etherscan.io/address/${account?.address}`,
        submitNativeTransaction: async (amount: number, toAddress: string) => {
          if (!toAddress.startsWith('0x')) {
            throw new Error('Invalid address');
          }

          // switch user to ETH mainnet if not already on it
          if (account.chainId !== mainnet.id) {
            await account.connector?.switchChain?.({ chainId: mainnet.id });
          }

          try {
            const res = await sendTransactionAsync({
              account: account.address,
              to: toAddress as `0x${string}`,
              value: parseEther(amount.toString()),
              chainId: mainnet.id, // require that transaction is on ETH mainnet
            });

            return {
              txid: res,
              explorerURL: `https://etherscan.io/tx/${res}`,
            };
          } catch (error) {
            console.error('Transaction failed', error);
            throw error;
          }
        },
      });
    } else {
      setAddressState(undefined);
    }
  }, [
    account.address,
    account.chainId,
    account.connector,
    address,
    addressState?.address,
    arweaveDisconnect,
    connected,
    connectors,
    ethereumDisconnect,
    publicKey,
    sendTransactionAsync,
    solanaConnection.connection,
    solanaDisconnect,
    solanaSendTransaction,
  ]);

  useEffect(() => {
    updateAddressState();
  }, [
    account,
    arweaveDisconnect,
    ethereumDisconnect,
    signMessageAsync,
    solanaDisconnect,
    updateAddressState,
  ]);
  return addressState;
};

export default useAddress;
