import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';

export const PAYMENT_SERVICE_FQDN =
  import.meta.env.VITE_NODE_ENV == 'production'
    ? 'payment.ardrive.io'
    : 'payment.ardrive.dev';

export const TURBO_WALLETS_URL = `https://${PAYMENT_SERVICE_FQDN}/info`;

export const WALLETCONNECT_PROJECT_ID = import.meta.env
  .VITE_WALLETCONNECT_PROJECT_ID;

export const KiB = 1024;
export const MiB = 1024 * KiB;
export const GiB = 1024 * MiB;

// LINKS
export const LINK_HOW_ARE_CONVERSIONS_DETERMINED =
  'https://help.ardrive.io/hc/en-us/articles/17043397992731';

// PUBLISHABLE KEYS

export const devStripePublishableKey =
  'pk_test_51JUAtwC8apPOWkDLh2FPZkQkiKZEkTo6wqgLCtQoClL6S4l2jlbbc5MgOdwOUdU9Tn93NNvqAGbu115lkJChMikG00XUfTmo2z';

export const prodStripePublishableKey =
  'pk_live_51JUAtwC8apPOWkDLMQqNF9sPpfneNSPnwX8YZ8y1FNDl6v94hZIwzgFSYl27bWE4Oos8CLquunUswKrKcaDhDO6m002Yj9AeKj';

export const STRIPE_PUBLISHABLE_KEY =
  import.meta.env.VITE_NODE_ENV == 'production'
    ? prodStripePublishableKey
    : devStripePublishableKey;

export const currencyLabels = {
  arweave: 'AR',
  ethereum: 'ETH',
  solana: 'SOL',
  kyve: 'KYVE',
  matic: 'MATIC',
  pol: 'POL',
  'base-eth': 'ETH',
};

export const valueStringError = `Error: Unable to fetch credit estimate`;

export const errorSubmittingTransactionToTurbo =
  'Error submitting transaction to Turbo. Please try again or contact support.';

export const SOLANA_RPC =
  import.meta.env.VITE_SOLANA_RPC || WalletAdapterNetwork.Devnet;
