import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { useState } from 'react';
import { useConnect } from 'wagmi';
import useWander from '../hooks/useWander';
import BaseModal from './BaseModal';
import BlockingMessageModal from './BlockingMessageModal';

const WalletSelectionModal = ({
  onClose,
  message,
}: {
  onClose: () => void;
  message: string;
}) => {
  const { connect } = useWander();

  const { connectAsync: ethConnect, connectors } = useConnect();

  const [connectingWallet, setConnectingWallet] = useState<string>();

  const { setVisible: setSolanaModalVisible } = useWalletModal();

  return (
    <BaseModal onClose={onClose} showCloseButton={true}>
      <div className="flex w-[24.5rem] flex-col items-center justify-center text-fg-muted">
        <div className="mb-10 text-2xl font-bold">Connect a Wallet</div>

        <div className="flex w-full flex-col gap-4">
          <button
            className="w-full bg-surface p-4"
            onClick={async () => {
              setConnectingWallet('Connecting to Wander...');
              try {
                await connect();
              } finally {
                setConnectingWallet(undefined);
              }
            }}
          >
            Connect via Wander
          </button>

          <button
            className="w-full bg-surface p-4"
            onClick={async () => {
              const metamask = connectors.find((c) => c.name === 'MetaMask');

              if (metamask) {
                setConnectingWallet('Connecting to MetaMask...');
                try {
                  // FIXME: There is an issue when metamask is not available that the following never returns, thus the
                  // finally block is never executed and the modal remains persistent. Accepting as edge-case for the
                  // moment and will need to find solution. (Testing for window.ethereum does not appear to work and it is
                  // showing up as defined.)
                  await ethConnect({ chainId: 1, connector: metamask });
                } catch (e) {
                  console.error(e);
                } finally {
                  setConnectingWallet(undefined);
                }
              } else {
                window.open('https://metamask.io/', '_blank');
              }
            }}
          >
            Connect via Metamask
          </button>
          <button
            className="w-full bg-surface p-4"
            onClick={() => setSolanaModalVisible(true)}
          >
            Connect via Solana
          </button>
        </div>

        <div className="flex size-[4.5rem] items-center pb-4"></div>
        <div className="text-mid text-sm">{message}</div>

        {connectingWallet && (
          <BlockingMessageModal
            onClose={() => setConnectingWallet(undefined)}
            message={connectingWallet}
          />
        )}
      </div>
    </BaseModal>
  );
};

export default WalletSelectionModal;
