import 'arconnect';
import { useCallback } from 'react';
import { useGlobalStore } from '../store';

const useWander = () => {
  const address = useGlobalStore((state) => state.wanderInfo?.address);
  const setArconnectInfo = useGlobalStore((state) => state.setWanderInfo);

  const connect = useCallback(async () => {
    if (!window.arweaveWallet) {
      window.open('https://wander.app/', '_blank');
      return;
    }

    await window.arweaveWallet.connect(
      ['ACCESS_ADDRESS', 'ACCESS_PUBLIC_KEY', 'SIGN_TRANSACTION', 'SIGNATURE'],
      {
        name: 'Turbo Topup',
      },
    );

    const address = await window.arweaveWallet.getActiveAddress();
    if (address) {
      setArconnectInfo({ address });
    }
  }, [setArconnectInfo]);

  const disconnect = useCallback(async () => {
    if (!window.arweaveWallet) {
      throw new Error('Wander is not installed');
    }

    await window.arweaveWallet.disconnect();
    if (address) {
      setArconnectInfo(undefined);
    }
  }, [address, setArconnectInfo]);

  return {
    address,
    connect,
    disconnect,
  };
};

export default useWander;
